var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.value,
        "max-width": "1300",
        width: "1300",
        persistent: "",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { tile: "", flat: "" } },
        [
          _c(
            "v-toolbar",
            { attrs: { flat: "" } },
            [
              _c(
                "v-toolbar-title",
                [_c("v-card-title", [_vm._v(" Related Items ")])],
                1
              ),
              _c("v-select", {
                staticClass: "ml-2",
                staticStyle: { "max-width": "35%" },
                attrs: {
                  label: "Item Group",
                  items: _vm.groups,
                  "item-text": "group_name",
                  "item-value": "id",
                  "menu-props": { offsetY: true },
                  "hide-details": "",
                  outlined: "",
                  dense: "",
                },
                model: {
                  value: _vm.groupId,
                  callback: function ($$v) {
                    _vm.groupId = $$v
                  },
                  expression: "groupId",
                },
              }),
              _c("v-spacer"),
              !_vm.readOnly
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        small: "",
                        disabled:
                          (_vm.localAddedItems.length === 0 &&
                            _vm.removedItems.length === 0) ||
                          _vm.adding,
                        loading: _vm.adding,
                        color: "primary",
                      },
                      on: { click: _vm.add },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.itemSearch ? "Add to Search" : "Add Items"
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  attrs: { small: "", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.updateOnClose
                        ? _vm.$emit("close", true)
                        : _vm.$emit("cancel")
                    },
                  },
                },
                [_vm._v(" Close ")]
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-container",
            { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
            [
              _c("v-data-table", {
                attrs: {
                  height: _vm.tableSize,
                  headers: _vm.headers,
                  items: _vm.relatedItems,
                  "items-per-page": -1,
                  "footer-props": { "items-per-page-options": [-1] },
                  loading: _vm.loading,
                  "fixed-header": "",
                  "calculate-widths": "",
                  "sort-by": "item_id",
                  dense: "",
                },
                on: {
                  "update:items": function ($event) {
                    _vm.relatedItems = $event
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `header.included`,
                      fn: function () {
                        return [
                          !_vm.readOnly
                            ? _c("v-simple-checkbox", {
                                attrs: {
                                  dark: "",
                                  disabled:
                                    _vm.relatedItems.length === 0 ||
                                    _vm.includeAllDisabled,
                                  dense: "",
                                  ripple: false,
                                },
                                model: {
                                  value: _vm.includeAll,
                                  callback: function ($$v) {
                                    _vm.includeAll = $$v
                                  },
                                  expression: "includeAll",
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: `item.included`,
                      fn: function ({ item }) {
                        return [
                          !_vm.isIncluded(item)
                            ? [
                                !_vm.readOnly
                                  ? _c("v-simple-checkbox", {
                                      attrs: {
                                        color: "primary",
                                        disabled: !_vm.isAvailableItem(item),
                                        dense: "",
                                        ripple: false,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.itemSelected($event, item)
                                        },
                                      },
                                      model: {
                                        value: item.selected,
                                        callback: function ($$v) {
                                          _vm.$set(item, "selected", $$v)
                                        },
                                        expression: "item.selected",
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            : _c("v-icon", { attrs: { color: "success" } }, [
                                _vm._v(" mdi-check "),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }